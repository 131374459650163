import {PageCollection} from '@simpli/resource-collection'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {plainToClass} from 'class-transformer'
import {Organization} from '@/model/resource/Organization'

@HttpExclude()
export class OrganizationSocialCollection extends PageCollection<
  OrganizationSocial
> {
  constructor() {
    super(OrganizationSocial)
  }

  resource?: IOrganizationSocialCollectionResourcesHolder
  editionList: OrganizationSocial[] = []

  @RequestExpose() idOrganizationFk: number[] = []

  get organization() {
    return (
      this.resource?.collectionOrganization.getManyIds(this.idOrganizationFk) ??
      null
    )
  }

  set organization(input) {
    this.idOrganizationFk = input?.map(item => item?.$id) ?? []
  }

  queryAsPage() {
    return this.listOrganizationSocial()
  }

  removeItem(item: OrganizationSocial) {
    this.items = this.items.filter(
      it =>
        it.idOrganizationFk === item.idOrganizationFk &&
        it.idSocialTypeFk !== item.idSocialTypeFk
    )
  }

  setItems(items: OrganizationSocial[]) {
    this.items = items.map(item => {
      item.organization = plainToClass(Organization, item.organization)

      return item
    })
  }

  async listOrganizationSocial() {
    const response = await Request.get(`/client/organization-social`, {
      params: this.params,
    })
      .name('listOrganizationSocial')
      .as(this)
      .getResponse()

    this.items.forEach(
      it => (it.organization = plainToClass(Organization, it.organization))
    )

    return response
  }
}

export interface IOrganizationSocialCollectionResourcesHolder {
  collectionOrganization: OrganizationCollection
  collectionSocialType: SocialTypeCollection
}
