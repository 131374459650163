





































import {Component, Prop, Provide, Vue, Emit} from 'vue-property-decorator'

import {Organization} from '../../model/resource/Organization'
import {InputOrganizationSocialSchema} from '@/schema/resource/OrganizationSocial/InputOrganizationSocialSchema'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'

@Component
export default class PersistOrganizationSocialView extends Vue {
  @Prop() organization!: Organization

  @Provide('validator') validator = this.$validator

  schema = new InputOrganizationSocialSchema()
  organizationSocial = new OrganizationSocial()

  async created() {
    this.organizationSocial.organization = this.organization
    await this.populateResource()
  }

  async populateResource() {
    await this.schema.collectionSocialType.queryAsPage()

    this.$await.done('getOrganizationSocial')
  }

  @Emit('backClick')
  backClick() {}

  @Emit('persist')
  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    return this.organizationSocial
  }
}
