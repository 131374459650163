






























































































import {Component, Prop, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import {Organization} from '@/model/resource/Organization'
import {ListOrganizationSocialSchema} from '@/schema/resource/OrganizationSocial/ListOrganizationSocialSchema'
import {OrganizationSocialCollection} from '@/model/collection/OrganizationSocialCollection'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'
import PersistOrganizationSocialView from '@/views/persist/PersistOrganizationSocialView.vue'

@Component({
  components: {PersistOrganizationSocialView, FilterToggle},
})
export default class ListOrganizationSocialView extends Mixins(
  MixinRouteMatch
) {
  @Prop() organization!: Organization
  schema = new ListOrganizationSocialSchema()
  collection = new OrganizationSocialCollection()
  isAdding = false

  get getItems() {
    return [...this.collection.items, ...this.collection.editionList]
  }

  async created() {
    this.collection.organization = [this.organization]
    this.collection.setItems(this.organization.organizationSocial || [])
    this.$root.$on('saveOrganizationSocial', this.persistPendingItem)
  }

  beforeDestroy() {
    this.$root.$off('saveOrganizationSocial', this.persistPendingItem)
  }

  persistPendingItem(id: number) {
    this.collection.editionList.forEach(contract => {
      contract.idOrganizationFk = id
      contract.persistOrganizationSocial()
    })
    this.collection.editionList = []
  }

  async removeItem(item: OrganizationSocial) {
    await this.$dialog.remove()
    await this.$await.run('hardQueryOrganizationSocial', async () => {
      const hasItem = this.collection.items.some(
        social =>
          social.url === item.url &&
          social.socialType?.title == item.socialType?.title
      )

      if (hasItem) {
        await item.removeOrganizationSocial()
        this.collection.removeItem(item)
      } else {
        this.collection.editionList = this.collection.editionList.filter(
          ({url, socialType}) =>
            !(item.url === url && item.socialType?.title === socialType?.title)
        )
      }
    })
  }

  addNew() {
    this.isAdding = true
  }

  cancelIsAdding() {
    this.isAdding = false
  }

  goToList(organizationSocial: OrganizationSocial) {
    this.collection.editionList.push(organizationSocial)
    this.cancelIsAdding()
  }
}
