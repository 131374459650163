









































import {Component, Prop, Provide, Vue} from 'vue-property-decorator'
import {Organization} from '@/model/resource/Organization'
import {InputOrganizationSchema} from '@/schema/resource/Organization/InputOrganizationSchema'
import ListOrganizationSocialView from '@/views/list/ListOrganizationSocialView.vue'
@Component({
  components: {ListOrganizationSocialView},
})
export default class PersistOrganizationView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputOrganizationSchema()
  organization = new Organization()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.organization.getOrganization(id)
    }

    this.$await.done('getOrganization')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    const id = await this.organization.persistOrganization()
    this.$root.$emit('saveOrganizationSocial', id)
    this.$toast.success('system.success.persist')
    await this.$nav.push('/organization/list')
  }
}
