import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'

export class ListOrganizationSocialSchema extends DefaultSchema {
  readonly name = 'ListOrganizationSocial'

  readonly fieldSet: FieldSet<OrganizationSocial> = {
    organization: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.organization?.$tag,
      },
    }),
    socialType: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.socialType?.$tag,
      },
    }),
    url: (schema): FieldComponent => ({
      is: Component.RenderAnchor,
      bind: {
        href: schema.model.url,
        label: schema.model.url,
        target: '_blank',
      },
    }),
  }
}
