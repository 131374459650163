import {DefaultSchema} from '@/schema/DefaultSchema'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {SocialTypeCollection} from '@/model/collection/SocialTypeCollection'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import {OrganizationSocial} from '@/model/resource/OrganizationSocial'
import * as Component from '@simpli/vue-input'

export class InputOrganizationSocialSchema extends DefaultSchema {
  collectionOrganization = new OrganizationCollection().noPagination()
  collectionSocialType = new SocialTypeCollection().noPagination()

  readonly name = 'InputOrganizationSocial'

  readonly fieldSet: FieldSet<OrganizationSocial> = {
    socialType: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionSocialType.items,
      },
    }),
    url: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 255,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required|url',
      },
    }),
  }
}
