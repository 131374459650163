/**
 * Input Schema of Organization
 * @author Simpli CLI generator
 */
import * as Component from '@simpli/vue-input'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'

import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {Organization} from '@/model/resource/Organization'
import {ColorRemoveHexConverter} from '@/converter/ColorRemoveHexConverter'
import FileManagerWithList from '@/components/FileManagerWithList.vue'

/* TODO: review generated schema */
export class InputOrganizationSchema extends DefaultSchema {
  readonly name = 'InputOrganization'

  readonly fieldSet: FieldSet<Organization> = {
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 128,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    colorHex: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'color',
        converter: new ColorRemoveHexConverter(),
        maxlength: 6,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    repositoryUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 512,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    location: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxLenght: 126,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    establishedYear: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        maxlength: 4,
        step: 1,
        label: this.translateFrom(schema.fieldName),
      },
    }),
    logoUrl: (): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'organization',
        compressDimension: $.auth.global.dappImageDimension,
        crop: false,
        class: 'scoped__image-url col-span-2',
      },
    }),
  }
}
